<template>
    <div class="card mb-5 mb-xxl-8 py-0 business-settings">
        <div class="card-body pt-6 px-0 pb-0">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-5 settings-section">
                <div class="me-sm-7 mb-4 d-flex justify-content-center justify-content-sm-start">
                    <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                        <img :src="businessData.icon_path" alt="image" />
                    </div>
                </div>

                <div class="flex-grow-1">
                    <div
                        class="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-column flex-sm-row align-items-center">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-center justify-content-sm-start mb-2">
                                <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                    {{ businessData.name }}
                                </a>
                            </div>
                            <div
                                class="d-flex flex-wrap justify-content-center justify-content-sm-start fw-bold fs-6 mb-4 pe-2">
                                <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <inline-svg src="/media/buying/icons/business.svg" />
                                    </span>
                                    <span v-for="(industry, index) in businessData.industries" :key="index">
                                        {{ index < businessData.industries.length - 1 ? industry + ", " : industry }}
                                    </span>
                                </a>
                                <a class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <inline-svg src="/media/buying/icons/general/gen018.svg" />
                                    </span>
                                    {{ businessData.country }}
                                </a>
                            </div>
                        </div>

                        <div class="d-flex align-items-center">
                            <router-link
                                to="/business/feedback/feedback-session/create"
                                class="btn main-btn me-3"
                                v-if="userInfo.can(BusinessUserPermissionTypeEnum.CREATE_FEEDBACK_SESSION)"
                            >
                                {{ $t("Create Feedback") }}
                            </router-link>
                            <div class="me-0">
                                <el-dropdown trigger="click" placement="bottom-end">
                                    <span class="el-dropdown-link">
                                        <button class="btn btn-sm btn-icon btn-bg-transparent btn-active-color-primary">
                                            <i class="bi bi-three-dots fs-1 fw-bolder"></i>
                                        </button>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item>
                                                <a :href="platformConfigs.platformLinks.Contactus"
                                                   class="note-color w-100"
                                                   target="_blank">{{ $t("Contact Support") }}</a>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <a :href="platformConfigs.platformLinks.Guides" class="note-color w-100"
                                                   target="_blank">{{ $t("Guides") }}</a>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <a :href="platformConfigs.platformLinks.Terms" class="note-color w-100"
                                                   target="_blank">{{ $t("Terms & Conditions") }}</a>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <a :href="platformConfigs.platformLinks.Privacy"
                                                   class="note-color w-100"
                                                   target="_blank">{{ $t("Privacy Policy") }}</a>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <a :href="platformConfigs.platformLinks.GDPRExport"
                                                   class="note-color w-100" target="_blank">{{ $t("GDPR Export") }}</a>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap flex-stack mt-md-8">
                        <div class="d-flex flex-column flex-grow-1">
                            <div class="d-flex flex-wrap user-counter">
                                <template v-for="(item, index) in counter">
                                    <div :class="`border border-gray-300 rounded min-w-175px py-3 px-4 mb-3 ${item.statusClass} ${(index+1) !== counter.length ? 'me-6' : ''}`">
                                        <div class="d-flex flex-column align-items-center">
                                            <div class="fw-bold fs-6 text-gray-400">{{ $t(item.label) }}</div>
                                            <div class="fs-1 fw-boldest">
                                                <span v-if="item.value">{{ item.value }}</span>
                                                <CustomLoader v-else />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="d-flex overflow-auto h-55px">
                <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                    <li class="nav-item">
                        <router-link
                            to="/business/settings/business-settings/overview"
                            class="nav-link text-active-primary mx-10 fs-4"
                            active-class="active"
                        >{{ $t("Overview") }}
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userInfo.can(BusinessUserPermissionTypeEnum.EDIT_BUSINESS)">
                        <router-link
                            class="nav-link text-active-primary mx-10 fs-4"
                            to="/business/settings/business-settings/settings"
                            active-class="active"
                        >{{ $t("Edit Company") }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link
                            class="nav-link text-active-primary mx-10 fs-4"
                            to="/business/settings/business-settings/registered-banks"
                            active-class="active"
                        >{{ $t("Registered Banks") }}
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="userInfo.can(BusinessUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)">
                        <router-link
                            class="nav-link text-active-primary mx-10 fs-4"
                            to="/business/settings/business-settings/permission-groups"
                            active-class="active"
                        >{{ $t("Permission Groups") }}
                        </router-link>
                    </li>
                    <!--          <li class="nav-item">-->
                    <!--            <router-link-->
                    <!--              class="nav-link text-active-primary mx-10 fs-4"-->
                    <!--              to="/business/settings/business-settings/notifications"-->
                    <!--              active-class="active"-->
                    <!--            >{{ $t("Notifications") }}-->
                    <!--            </router-link>-->
                    <!--          </li>-->
                </ul>
            </div>
        </div>
    </div>
    <router-view></router-view>
</template>
<script>
import CompanyDetails from "@/buying-teams/shared-components/settings/CompanyDetails";
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "BusinessSettings",

    components: {
        CustomLoader,
        CompanyDetails
    },

    data() {
        return {
            BusinessUserPermissionTypeEnum
        }
    },

    computed: {
        platformConfigs() {
            return store.getters.platformConfigs;
        },
        businessData() {
            return store.getters.business;
        },
        userInfo() {
            return store.getters.currentUser;
        },
        getBusinessInfo() {
            return store.getters.getBusinessInfoData;
        },
        counter() {
            let cnt = [
                {
                    label: "Average Score",
                    value: null,
                    key: "score",
                    statusClass: "average_score"
                },
                {
                    label: "Average Importance",
                    value: null,
                    key: "importance",
                    statusClass: ""
                },
                {
                    label: "Average Gap",
                    value: null,
                    key: "gap",
                    statusClass: ""
                }
            ];

            cnt.map(it => {
                it.value = this.getBusinessInfo[it.key] === null ? "0" : this.getBusinessInfo[it.key];
            });

            return cnt;
        }
    },

    async mounted() {
        setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.userInfo.first_name},  <span>${this.$t("welcomeBack")}</span>` });
        store.dispatch("getBusinessInfo");
        store.dispatch("getBusinessSettings");
    },
    methods: {}

};
</script>
<style lang="scss" scoped>
.infoColumn {
    &:hover {
        border-color: #2BC490 !important;
        color: #2BC490;
    }
}

.nav-item {
    white-space: nowrap;
}

.symbol.symbol-lg-160px.symbol-fixed > img {
    object-fit: cover;
    object-position: top;
}

.business-settings {
    .user-counter {
        .border {
            &.status-danger {
                border-color: #E22D21 !important;

                .fw-boldest {
                    color: #E22D21;
                }
            }

            &.status-warning {
                border-color: #FAD03B !important;

                .fw-boldest {
                    color: #FAD03B;
                }
            }

            &.status-success {
                border-color: #2BC490 !important;

                .fw-boldest {
                    color: #2BC490;
                }
            }
        }
    }

    .user-counter {
        .average_score {
            position: relative;
            margin-right: 39px !important;

            &:before {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background: #000000;
                top: 0;
                right: -20px;
                opacity: 0.1;
            }
        }
    }

    @media (max-width: 685px) {
        .settings-section .user-counter {
            margin-top: 0;
        }
    }
    @media (max-width: 576px) {
        .user-counter > div.average_score,
        .user-counter > div {
            margin-right: 0 !important;
            width: 100%;

            &:before {
                display: none;
            }
        }
    }
}
</style>
